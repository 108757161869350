<template>
    <modal ref="modalRemplazarProducto" titulo="Cambiar Producto" tamano="modal-lg" adicional="Remplazar" no-aceptar :cargado-modal="cargando" @adicional="accion_remplazar">
        <div class="row mx-0 justify-center">
            <div class="col-8">
                <el-select
                v-model="model.id_producto_nuevo"
                filterable
                remote
                reserve-keyword
                placeholder="Buscar producto"
                :remote-method="remoteMethod"
                :loading="loading"
                class="w-100"
                @change="set_minimo"
                >
                    <el-option
                    v-for="item in opciones"
                    :key="item.id"
                    :label="`${item.sku} - ${item.nombre} - ${item.presentacion}`"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
        <div v-if="model.id_producto_nuevo != null" class="row mx-0 my-4 justify-center">
            <div class="col-12">
                <p class="text-general px-4 f-600 my-2 f-17 ml-3">
                    Reemplazo
                </p>
            </div>
            <div class="col-12">
                <div class="row mx-0">
                    <div class="col-auto px-2 text-center">
                        <img :src="producto_nuevo.imagen" class="obj-cover br-12 border p-2" width="169" height="169" />
                    </div>
                    <div class="col">
                        <p class="text-general f-600 f-17">
                            {{ producto_nuevo.nombre }}
                        </p>
                        <div class="row mx-0 mt-2">
                            <p v-if="producto_nuevo.promocion" class="mt-2">
                                <span class="text-general f-600"> {{ convertMoney(producto_nuevo.data_promocion.promo_valor,idm_moneda) }}</span>
                                <span class="text-general2 f-600 f-13" style="text-decoration:line-through;">  {{ convertMoney(producto_nuevo.precio,idm_moneda) }} </span>
                            </p>
                            <p v-else class="mt-2">
                                <span class="text-general f-600"> {{ convertMoney(producto_nuevo.precio,idm_moneda) }}</span>
                            </p>
                        </div>
                        <div class="row mx-0 mt-1">
                            <span v-if="producto_nuevo.promocion" class="bg-general text-white px-2 br-12 f-13">
                                {{ producto_nuevo.data_promocion.texto }}
                            </span>
                        </div>
                        <p class="text-general f-13 mt-1">
                            {{ producto_nuevo.descripcion }}
                        </p>
                        <p class="text-general my-2 f-14">
                            <b class="f-500">Barcode: </b> {{ producto_nuevo.barcode != null ? producto_nuevo.barcode : 'No registra' }}
                            <b class="f-500 ml-3">SKU: </b> {{ producto_nuevo.sku != null ? producto_nuevo.sku : 'No registra' }}
                        </p>
                        <p class="text-general my-2 f-14">
                            <b class="f-500">Unidades disponibles: </b> {{ producto.unidades_disponibles }}
                        </p>
                        <p class="text-general my-2 f-14">
                            <b class="f-500">Venta sin stock: </b> {{ producto_nuevo.venta_sin_stock == 1 ? 'Si' : 'No' }}
                        </p>
                        <p class="text-general f-15 my-2">
                            <b class="f-500">Presentacion:</b> {{ producto_nuevo.presentacion }}
                        </p>
                        <p class="text-general f-15 my-2">
                            <b class="f-500">Unidad de Medida:</b> {{ producto_nuevo.unidad }}
                        </p>
                        <p class="text-general f-15 my-2">
                            <b class="f-500">Venta Mínima:</b>  {{ producto_nuevo.cantidad_tipo == 1 ? agregarSeparadoresNumero(producto_nuevo.cantidad_minima,0) : agregarSeparadoresNumero(producto_nuevo.cantidad_minima, 2) }} {{ producto_nuevo.unidad }}
                        </p>
                        <div class="row mx-0 justify-content-between border br-12 py-2">
                            <div class="col-12 px-0">
                                <p class="text-general px-4 f-15">
                                    Editar la cantidad
                                </p>
                            </div>
                            <div class="col-5">
                                <el-input-number
                                v-model="model.cantidad_nueva" size="medium"
                                class="mb-2 transparent-input"
                                :min="minimo_nuevo"
                                :step="salto_nuevo"
                                :step-strictly="entero_nuevo"
                                :precision="presicion_nuevo"
                                />
                            </div>
                            <div class="col d-middle-center">
                                <p class="text-general">
                                    <b>
                                        Nuevo total:
                                    </b>
                                    {{ convertMoney(total_nuevo,idm_moneda) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Producto Original -->
        <div class="row mx-0 my-4 justify-center">
            <div class="col-12">
                <p class="text-general px-3 f-600 my-2 f-17 ml-2">
                    Producto Original
                </p>
            </div>
            <div class="col-12">
                <div class="row mx-0">
                    <div class="col-auto px-2 text-center">
                        <img :src="producto.imagen" class="obj-cover br-12 border p-2" width="169" height="169" />
                    </div>
                    <div class="col">
                        <p class="text-general f-600 f-17">
                            {{ producto.nombre }}
                        </p>
                        <div class="row mx-0 mt-2">
                            <p v-if="producto.promocion" class="mt-2">
                                <span class="text-general f-600"> {{ convertMoney(producto.precio_final,idm_moneda) }}</span>
                                <span class="text-gris descuento f-600 f-14">  {{ convertMoney(producto.precio,idm_moneda) }} </span>
                            </p>
                            <p v-else class="mt-2">
                                <span class="text-general f-600"> {{ convertMoney(producto.precio,idm_moneda) }}</span>
                            </p>
                        </div>
                        <div class="row mx-0 mt-2">
                            <span v-if="producto.promocion" class="bg-general text-white px-2 br-12 f-13">
                                {{ producto.data_promocion.texto }}
                            </span>
                        </div>
                        <p class="text-general f-13 mt-1">
                            {{ producto.descripcion }}
                        </p>
                        <p class="text-general my-2 f-14">
                            <b class="f-500">Barcode: </b> {{ producto.barcode != null ? producto.barcode : 'No registra' }}
                            <b class="f-500 ml-3">SKU: </b> {{ producto.sku != null ? producto.sku : 'No registra' }}
                        </p>
                        <p class="text-general my-2 f-14">
                            <b class="f-500">Unidades disponibles: </b> {{ producto.unidades_disponibles }}
                        </p>
                        <p class="text-general my-2 f-14">
                            <b class="f-500">Venta sin stock: </b> {{ producto.venta_sin_stock == 1 ? 'Si' : 'No' }}
                        </p>
                        <p class="text-general f-15 my-1">
                            <b class="f-500">Presentacion:</b> {{ producto.presentacion }}
                        </p>
                        <p class="text-general f-15 my-1">
                            <b class="f-500">Unidad de Medida:</b> {{ producto.unidad }}
                        </p>
                        <p class="text-general f-15 my-1">
                            <b class="f-500">Venta Mínima:</b> {{ producto.cantidad_minima }} {{ producto.unidad }}
                        </p>
                        <div class="row mx-0 justify-content-between">
                            <p class="col-auto px-0 text-general">
                                <b class="f-500">Cantidad pedida: </b> {{ producto.cantidad_pedida }}
                            </p>
                            <p class="col-auto text-general">
                                <b class="f-500">Valor Total: </b> {{ producto.total_pedida }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            cargando:false,

            opciones: [],
            loading:false,
            minimo_nuevo:0,
            salto_nuevo:0,
            entero_nuevo:false,
            presicion_nuevo:0,

            producto:{nombre:'',imagen:null},
            producto_nuevo:{},
            datos:{},

            model:{
                id_producto_nuevo:null,
                cantidad:'',
                cantidad_nueva:0
            },
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido: 'pedidos/pedidos_admin/pedido',
        }),
        idm_moneda(){
            return this.pedido.idm_moneda
        },
        total_nuevo(){
            if(this.producto_nuevo.promocion){
                return this.producto_nuevo.data_promocion.promo_valor * this.model.cantidad_nueva
            }else{
                return this.producto_nuevo.precio * this.model.cantidad_nueva
            }
        }
    },
    methods: {
        toggle(){
            this.$refs.modalRemplazarProducto.toggle()
        },
        limpiar(){
            this.opciones = []
            this.model = {
                id_producto_nuevo:null,
                cantidad_nueva:0
            }
            this.minimo_nuevo = 0
            this.salto_nuevo = 0
            this.entero_nuevo = false
            this.presicion_nuevo =0
        },
        async load_data(prod){
            this.limpiar()
            this.datos = prod

            let decimalesFrancion = 0
            if(prod.producto.cantidad_tipo === 1){
                decimalesFrancion = 0
            } else{
                decimalesFrancion = 2
            }


            this.producto = {
                id:prod.producto.id,
                nombre:prod.producto.nombre,
                detalle_desconocido:prod.producto.detalle_desconocido,
                descripcion:prod.producto.descripcion,
                imagen:prod.producto.imagen,
                precio: prod.unidad_teorica,
                precio_final: prod.unidad_final,
                promocion: prod.promocion,
                presentacion:prod.producto.presentacion,
                unidad:prod.producto.unidad,
                cantidad_minima:this.agregarSeparadoresNumero(prod.producto.cantidad_minima,decimalesFrancion),
                cantidad_pedida: this.agregarSeparadoresNumero(prod.cantidad,decimalesFrancion) + prod.producto.unidad,
                total_pedida: this.convertMoney(prod.total_final,this.idm_moneda),
                barcode: prod.barcode,
                sku: prod.sku,
                unidades_disponibles: this.agregarSeparadoresNumero(prod.unidades_disponibles),
                venta_sin_stock: prod.venta_sin_stock
            }
            if(prod.promocion){
                this.producto.data_promocion = prod.data_promocion
            }
            this.$refs.modalRemplazarProducto.toggle()
        },
        async accion_remplazar(){
            try {
                this.cargando = true
                if(this.model.id_producto_nuevo === null){
                    this.notificacion('Advertencia','Por favor seleccione el producto nuevo','warning')
                    return
                }
                let model = {
                    id_producto:this.model.id_producto_nuevo,
                    cantidad:this.model.cantidad_nueva
                }
                const {data} = await Pedidos.change_producto(this.datos.id, model)
                this.notificacion('Registro Actualizado','Se ha remplazado el producto correctamente','success')
                //recalculamos todos los datos
                this.$emit('update')
                this.$refs.modalRemplazarProducto.toggle()
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false
            }
        },
        async remoteMethod(query){
            try {
                if(query.length > 3){

                    this.loading = true

                    let params = {
                        id_pedido: this.id_pedido,
                        query
                    }

                    const {data} = await Pedidos.buscar_productos(params)
                    this.opciones = data.productos
                }

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        set_minimo(id){
            let obj = this.opciones.find(o=>o.id === id)
            //console.log(obj);
            this.model.cantidad_nueva = parseFloat(obj.cantidad_minima)
            this.minimo_nuevo = parseFloat(obj.cantidad_minima)
            this.salto_nuevo = parseFloat(obj.cantidad_minima)
            this.entero_nuevo =obj.cantidad_tipo === 1
            this.presicion_nuevo = obj.cantidad_tipo === 1 ? 0 : 2
            this.producto_nuevo = obj
        },
    }
}
</script>
<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
</style>
